<template>
  <div v-if="usersData" class="table-container">
    <PreLoader v-if="isLoading" />
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="4">{{ $t("Users.data") }}</th>
          <th colspan="2">{{ $t("UserInterests.data") }}</th>
          <th colspan="4">{{ $t("UserInterests.followUp.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
          <th>{{ $t("Users.phone") }}</th>
          <th>{{ $t("UserInterests.interests") }}</th>
          <th>{{ $t("UserInterests.others") }}</th>
          <th>{{ $t("UserInterests.followUp.total") }}</th>
          <th>{{ $t("UserInterests.followUp.totalFollowUped") }}</th>
          <th>{{ $t("UserInterests.followUp.totalReplyed") }}</th>
          <th>{{ $t("UserInterests.others") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(user, index) in usersData">
          <tr :key="user.userToken">
            <td :rowspan="rowSpanLastFollow(index)" :class="getClass(user)">
              {{ ++index + filterData.currentIndex }}
            </td>
            <td>
              <img
                class="item-img-table"
                :src="fullPathFileFromServer(user.userImagePath, defaultImg)"
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>{{ isDataExist(user.fullCode) }}</td>
            <td>
              {{ isDataExist(user.userNameCurrent) }}
            </td>
            <td class="table-phone-number">
              {{ isDataExist(user.userPhoneWithCC) }}
            </td>
            <!-- UserInterests.data -->
            <td>
              {{
                isDataExist(
                  user.userInterestStatisticData.totalCountSysteamInterests
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  user.userInterestStatisticData.totalCountOthersInterests
                )
              }}
            </td>
            <!-- UserInterests.followUp.data -->
            <td>
              {{
                isDataExist(
                  user.userInterestStatisticData.totalCountFollowInterests
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  user.userInterestStatisticData.totalCountFollowUpEmployees
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  user.userInterestStatisticData
                    .totalCountFollowInterestsIsReply
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  user.userInterestStatisticData
                    .totalCountFollowInterestsNotReply +
                    user.userInterestStatisticData
                      .totalCountFollowInterestsPhoneIsClose
                )
              }}
            </td>
            <td class="fmenu-item-container">
              <FloatingMenu>
                <li>
                  <button
                    @click="
                      updateLastFollowShowHide(index, user);
                      getUserDetails(
                        user.lastFollowUpSessionData.startSessionUserToken
                      );
                    "
                    :title="$t('UserInterests.lastFollowUp.data')"
                  >
                    <img src="@/assets/images/home-phone.svg" />
                  </button>
                </li>
                <li v-if="checkPrivilege(hasFollowInterestAdd())">
                  <button
                    @click="
                      setFollowInterestData(user);
                      openBottomSheet('FollowInterestAdd');
                    "
                    :title="$t('FollowInterests.add')"
                  >
                    <img src="@/assets/images/plus.svg" />
                  </button>
                </li>
                <li v-if="hasFollowInterest">
                  <router-link
                    :to="{
                      name: 'FollowInterests',
                      params: { userToken: user.userToken },
                    }"
                    :title="$t('FollowInterests.modelName')"
                  >
                    <img src="@/assets/images/followInterests.svg" />
                  </router-link>
                </li>
                <li v-if="UserInterestRoute">
                  <router-link
                    :to="{
                      name: UserInterestRoute,
                      params: { userToken: user.userToken },
                    }"
                    :title="$t('UserSysteamOtherInterests.modelName')"
                  >
                    <img src="@/assets/images/UserSysteamOtherInterests.svg" />
                  </router-link>
                </li>
              </FloatingMenu>
            </td>
          </tr>
          <!-- last follow up -->
          <tr v-if="showLastFollow(index)" :key="index">
            <td colspan="11">
              <table class="my-table">
                <thead>
                  <tr @click="updateLastFollowShowHide(index, user)">
                    <th colspan="6">
                      {{ $t("UserInterests.lastFollowUp.data") }}
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {{
                        $t("UserInterests.lastFollowUp.followInterestNumber")
                      }}
                    </th>
                    <th>
                      {{ $t("UserInterests.lastFollowUp.replyStatusType") }}
                    </th>
                    <th>
                      {{ $t("UserInterests.lastFollowUp.communicationType") }}
                    </th>
                    <th>
                      {{ $t("UserInterests.lastFollowUp.followBy") }}
                    </th>
                    <th>
                      {{ $t("UserInterests.lastFollowUp.dateAndTime") }}
                    </th>
                    <th>
                      {{ $t("EducationalScheduleTimes.duration") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{
                        isDataExist(
                          user.userInterestStatisticData
                            .lastFollowInterestInfoData.followInterestNumber
                        )
                      }}
                    </td>
                    <td>
                      {{
                        isDataExist(
                          user.userInterestStatisticData
                            .lastFollowInterestInfoData
                            .replyStatusTypeNameCurrent
                        )
                      }}
                    </td>
                    <td>
                      {{
                        isDataExist(
                          user.userInterestStatisticData
                            .lastFollowInterestInfoData
                            .communicationTypeInfoData
                            .communicationTypeNameCurrent
                        )
                      }}
                    </td>
                    <td>
                      {{
                        isDataExist(
                          user.userInterestStatisticData
                            .lastFollowInterestInfoData.userCreatedData
                            .userNameCurrent
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formateDateTimeLang(
                          user.userInterestStatisticData
                            .lastFollowInterestInfoData.creationDate,
                          user.userInterestStatisticData
                            .lastFollowInterestInfoData.creationTime
                        )
                      }}
                    </td>
                    <td>
                      {{
                        isDataExist(
                          user.lastFollowUpSessionData.sessionDurationCurrent
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <!-- current follow up -->
          <tr
            v-if="
              showLastFollow(index) &&
              !user.lastFollowUpSessionData.followUpSessionIsExpierStatus
            "
            :key="index + 1"
          >
            <td colspan="11">
              <table class="my-table">
                <thead>
                  <tr>
                    <th colspan="4">
                      {{ $t("UserInterests.currentFollowUp.data") }}
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {{ $t("UserInterests.currentFollowUp.followBy") }}
                    </th>
                    <th>
                      {{ $t("UserValidSettings.UserFullCode") }}
                    </th>
                    <th>
                      {{ $t("UserInterests.currentFollowUp.dateAndTime") }}
                    </th>
                    <th>
                      {{ $t("time") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ isDataExist(currentSessionUserDetails.name) }}
                    </td>
                    <td>
                      {{ isDataExist(currentSessionUserDetails.code) }}
                    </td>
                    <td>
                      {{
                        formateDateTimeLang(
                          user.lastFollowUpSessionData.systemEndSessionDate,
                          user.lastFollowUpSessionData.systemEndSessionTime
                        )
                      }}
                    </td>
                    <td>
                      <CustomCountDownTimer
                        :endAt="
                          new Date(
                            user.lastFollowUpSessionData.systemEndSessionDateTime
                          ).getTime()
                        "
                      />
                      <!-- v-on:end="countDownTimerEnd = true" -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  formateDateTimeLang,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import {
  hasFollowInterestAdd,
  hasFollowInterest,
  hasUserSysteamInterest,
  hasUserOtherInterest,
} from "./../../../../utils/privilegeHelper";
import generalMixin from "./../../../../utils/generalMixin";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import CustomCountDownTimer from "./../../../../components/general/CustomCountDownTimer.vue";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import { STATUS } from "./../../../../utils/constants";
import User from "./../../../../models/users/User";
import apiUser from "./../../../../api/user";

export default {
  name: "UsersTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
    CustomCountDownTimer,
    PreLoader,
  },
  props: ["usersData", "filterData", "defaultImg"],
  data() {
    return {
      lastFollowShowHideList: [],
      userDetails: new User(),
    };
  },
  computed: {
    currentSessionUserDetails() {
      let userData = {
        code: this.userDetails.fullCode,
        name: this.userDetails.userNameCurrent,
      };
      return userData;
    },
    UserInterestRoute() {
      if (hasUserSysteamInterest()) return "UserSysteamInterests";
      else if (hasUserOtherInterest()) return "UserOtherInterests";
      else return "";
    },
  },
  async created() {
    await this.setLastFollowShowHideList();
  },
  methods: {
    checkPrivilege,
    formateDateTimeLang,
    isDataExist,
    fullPathFileFromServer,
    hasFollowInterestAdd,
    hasFollowInterest,
    setFollowInterestData(user) {
      this.$emit("setFollowInterestData", user.userToken);
    },
    async getUserDetails(userToken) {
      if (this.userDetails.userToken) return;
      this.isLoading = true;
      try {
        const response = await apiUser.getDetails({
          token: userToken,
        });
        if (response.data.status == STATUS.SUCCESS) {
          this.userDetails.fillData(response.data.user);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    rowSpanLastFollow(index) {
      let rowSpan = this.lastFollowShowHideList[index]["rowSpanLastFollow"];
      return rowSpan;
    },
    getClass(user) {
      let className = "";
      // in Session
      if (
        user.lastFollowUpSessionData &&
        !user.lastFollowUpSessionData.followUpSessionIsExpierStatus
      ) {
        className = "bg-green";
      }

      return className;
    },

    showLastFollow(i) {
      let index = i - 1;
      return this.lastFollowShowHideList[index]["showLastFollow"];
    },
    updateLastFollowShowHide(i, user) {
      let index = i - 1;
      this.lastFollowShowHideList[index]["showLastFollow"] =
        !this.lastFollowShowHideList[index]["showLastFollow"];

      if (this.lastFollowShowHideList[index]["rowSpanLastFollow"] == 1) {
        this.lastFollowShowHideList[index]["rowSpanLastFollow"] = 2;
        if (
          //user has active session
          user.lastFollowUpSessionData &&
          !user.lastFollowUpSessionData.followUpSessionIsExpierStatus
        ) {
          this.lastFollowShowHideList[index]["rowSpanLastFollow"] = 3;
        }
      } else {
        this.lastFollowShowHideList[index]["rowSpanLastFollow"] = 1;
      }
    },
    setLastFollowShowHideList() {
      this.lastFollowShowHideList = this.usersData.map(() => {
        return {
          showLastFollow: false,
          rowSpanLastFollow: 1,
        };
      });
    },
  },
};
</script>
