import UserSessionData from "./UserSessionData";

export default class FollowUpSession {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userSessionData = new UserSessionData();
  }
  fillData(data) {
    if (data) {
      this.userSessionData.fillData(data.userSessionData);
    } else {
      this.setInitialValue();
    }
  }
}
