<template>
  <div class="countdown-timer">
    <vue-countdown-timer
      @start_callback="startCallBack()"
      @end_callback="endCallBack()"
      :start-time="startAt"
      :end-time="endAt"
      :end-text="endText"
      :day-txt="sbliterText"
      :hour-txt="sbliterText"
      :minutes-txt="sbliterText"
      :seconds-txt="null"
    >
      <template slot="countdown" slot-scope="scope">
        <span>{{ scope.props.days }}</span
        ><span>{{ scope.props.dayTxt }}</span>
        <span>{{ scope.props.hours }}</span
        ><span>{{ scope.props.hourTxt }}</span>
        <span>{{ scope.props.minutes }}</span
        ><span>{{ scope.props.minutesTxt }}</span>
        <span>{{ scope.props.seconds }}</span
        ><span>{{ scope.props.secondsTxt }}</span>
      </template>
      <template slot="end-text" slot-scope="scope">
        <span style="color: red">{{ scope.props.endText }}</span>
      </template>
    </vue-countdown-timer>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: {
    startAt: {
      type: Number,
      default: () => new Date().getTime(),
    },
    endAt: {
      type: Number,
      default: () => new Date().getTime() + 5000,
    },
    sbliterText: {
      type: String,
      default: ":",
    },
    endText: {
      type: String,
      default: "00:00:00:00",
    },
  },
  data() {
    return {};
  },
  methods: {
    startCallBack: function () {
      this.$emit("start");
    },
    endCallBack: function () {
      this.$emit("end");
    },
  },
};
</script>
<style scoped lang="scss">
.countdown-timer {
  direction: ltr;
  text-align: center;
}
</style>
