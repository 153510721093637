import LastFollowUpSessionData from "./LastFollowUpSessionData";

export default class UserSessionData {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.studentToken = "";
    this.lastFollowUpSessionData = new LastFollowUpSessionData();
    this.hasLastFollowUpSessionData = false;
    this.sessionCounterEnded = false;
  }
  fillData(data) {
    if (data) {
      this.studentToken = data.studentToken ?? "";
      this.lastFollowUpSessionData.fillData(data.lastFollowUpSessionData);
      this.hasLastFollowUpSessionData = data.lastFollowUpSessionData
        ? true
        : false;
      this.sessionCounterEnded = false;
    } else {
      this.setInitialValue();
    }
  }
}
